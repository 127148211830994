const yearRange = () => {
    var oneDay = 360*24*60*60*1000; // days*hours*minutes*seconds*milliseconds
    var firstDate = new Date('2015-01-01');
    var secondDate = new Date();
    
    return Math.round(Math.abs((firstDate.getTime() - secondDate.getTime())/(oneDay)));
  }

const STATS = [
    {
        title: 'Tools & Software',
        items: [{
                name: 'Git',
                description: 'I love version control',
                source: 'https://git-scm.com/',
            },
            {
                name: 'NPM',
                description: 'I love version control',
                source: 'https://www.npmjs.com/',
            },
            {
                name: 'Yarn',
                description: 'I love version control',
                source: 'https://yarnpkg.com/',
            },
            {
                name: 'Webpack',
                description: 'I love version control',
                source: 'https://webpack.js.org/',
            },
            {
                name: 'Gulp',
                description: 'I love version control',
                source: 'https://gulpjs.com/',
            },
            {
                name: 'Grunt',
                description: 'I love version control',
                source: 'https://gruntjs.com/',
            },
            {
                name: 'VSTS',
                description: 'Worked on several projects with Visual Studio Team Foundation Services',
                source: 'https://visualstudio.microsoft.com/team-services/',
            },
            {
                name: 'Google Cloud Services',
                source: 'https://cloud.google.com/',
            },
            {
                name: 'AWS',
                description: 'Setup syncing for media storage.',
                source: 'https://aws.amazon.com/'
            },
            {
                name: 'Adobe Suite',
                description: 'Mostly Photoshop and Illustrator, but I also use other Adobe tools such as After Effects, Flash, Dreamweaver, InDesign, etc.',
                source: 'https://www.adobe.com/creativecloud.html',
            },
            {
                name: 'Sketch',
                description: 'A very useful tool I use to create quick mocks-ups',
                source: 'https://www.sketchapp.com/',
            },
            {
                name: 'VS Code',
                description: 'Worked on several projects with Visual Studio Team Foundation Services',
                source: 'https://code.visualstudio.com/',
            },
            {
                name: 'WebStorm',
                description: 'Worked on several projects with Visual Studio Team Foundation Services',
                source: 'https://www.jetbrains.com/webstorm/',
            },
            {
                name: 'WordPress',
                description: 'Developed custom templates and plugins for clients',
                source: 'https://wordpress.org/',
            },
            {
                name: 'ModX',
                description: 'Developed custom 3rd party plugins for ModX',
                source: 'https://modx.com/',
            },
            {
                name: 'Joomla',
                description: 'Developed custom templates for clients',
                source: 'https://www.joomla.org/',
            },
            {
                name: 'And Many More...',
                description: 'I\'m highly proficient in Visual Studio Code, but I can work with any IDE such as Visual Studio (Windows), WebStorm, Atom, etc.',
                source: null,
            },
        ]
    },
    {
        title: 'Languages & Architectures',
        items: [{
                name: 'HTML 5',
                description: 'The only mark up language I cannot get away from',
                source: 'https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5',
            },
            {
                name: 'CSS 3',
                description: 'Style this, style that, style everything!',
                source: 'https://www.w3schools.com/css/',
            },
            {
                name: 'Sass',
                description: 'I love me some Sass',
                source: 'https://sass-lang.com/',
            },
            {
                name: 'Less',
                description: 'And some Less',
                source: 'http://lesscss.org/',
            },
            {
                name: 'JSS',
                description: 'My old friend...',
                source: 'https://cssinjs.org/',
            },
            {
                name: 'BEM',
                description: 'And some Less',
                source: 'https://css-tricks.com/bem-101/',
            },
            {
                name: 'SMACSS',
                description: 'And some Less',
                source: 'http://smacss.com/',
            },
            {
                name: 'OOCSS',
                description: 'And some Less',
                source: 'https://www.smashingmagazine.com/2011/12/an-introduction-to-object-oriented-css-oocss/',
            },
            {
                name: 'JavaScript',
                description: 'My old friend...',
                source: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript',
            },
            {
                name: 'ES6+ / ECMAScript 2015+',
                description: null,
                source: 'https://www.w3schools.com/js/js_es6.asp',
            },
            {
                name: 'Node.js',
                description: `My best friend, we have at least a ${yearRange()} year relationship`,
                source: 'https://nodejs.org/en/about/',
            },
            {
                name: 'PHP',
                description: 'We\'re still good friends though',
                source: 'https://www.php.net/',
            },
            {
                name: 'MySQL',
                description: 'I love relational databases!',
                source: 'https://www.mysql.com/',
            },
            {
                name: 'NoSQL',
                description: 'And I love nonrelational databases!',
                source: 'https://aws.amazon.com/nosql/',
            },
            {
                name: 'REST APIs',
                source: 'https://restfulapi.net/',
            },
            {
                name: 'GraphQL',
                description: 'An awesome query lanaguage for APIs',
                source: 'https://graphql.org/',

            },
            {
                name: 'Python',
                description: 'A programming language and a nonvenomous snake',
                source: 'https://en.wikipedia.org/wiki/Pythonidae',
            },
            {
                name: 'And Many more...',
                description: 'I\'ve worked with other languages such as the .NET framework in Visual Studios',
                source: null,
            },
        ],
    },
    {
        title: 'Libraries & Frameworks',
        items: [{
                name: 'React',
                description: 'I consider this to be my top pick and favorite framework to work with',
                source: 'https://reactjs.org/',
            },
            {
                name: 'React Native',
                description: 'With this framework I am able to create native mobile apps for both iOS and Android',
                source: 'https://facebook.github.io/react-native/',
            },
            {
                name: 'Angular 1 & 2+',
                description: 'Another framework I enjoy to work with',
                source: 'https://angular.io/',
            },
            {
                name: 'VueJS',
                description: 'Simple and easy framework',
                source: 'https://vuejs.org/',
            },
            {
                name: 'ExpressJS',
                description: 'My favorite back-end Node.js framework',
                source: 'https://expressjs.com/',
            },
            {
                name: 'Tailwind',
                description: 'My awesome responsive front-end component library',
                source: 'https://tailwindcss.com/',
            },
            {
                name: 'Bootstrap',
                description: 'My awesome responsive front-end component library',
                source: 'https://getbootstrap.com/',
            },
            {
                name: 'KnockoutJS',
                description: 'And old framework I love and still a great tool',
                source: 'https://knockoutjs.com/',
            },
            {
                name: 'HandlebarsJS',
                description: 'Another old framework I love',
                source: 'https://handlebarsjs.com/',
            },
            {
                name: 'EJS',
                description: 'My simple JavaScript templating tool',
                source: 'https://ejs.co/',
            },
            {
                name: 'jQuery',
                description: 'jQuery and I go way back early in my career, I stick to this framework when I am not using any Node.js framework',
                source: 'https://jquery.com/',
            },
            {
                name: 'jQuery Mobile',
                description: 'Another framework I go way back with',
                source: 'https://jquerymobile.com/',
            },
            {
                name: 'PixiJS',
                description: 'My favorite JavaScript 2D WebGL renderer',
                source: 'http://www.pixijs.com/',
            },
            {
                name: 'PhaserJS',
                description: 'My favotire JavaScript game engine',
                source: 'https://phaser.io/',
            },
            {
                name: 'Kendo UI',
                description: 'A powerful JavaScript UI library I handle with care',
                source: 'https://www.telerik.com/kendo-ui',
            },
            {
                name: 'Material UI',
                description: 'My all time favorite UI library for React',
                source: 'https://material-ui.com/',
            },
            {
                name: 'Codeigniter',
                description: 'My good old powerful PHP framework to develop awesome web apps',
                source: 'https://codeigniter.com/',
            },
            {
                name: 'And Many more...',
                description: 'There is a ton of libraries and frameworks I\'ve worked within my career, and the list keeps growing',
                source: null,
            },
        ],
    },
    {
        title: 'Other Areas of Expertise',
        items: [
            {
                name: 'UI/UX Design',
                description: 'I\'m not just a UI developer, I also have a creative artistic side when it comes to UI design',
                source: 'https://careerfoundry.com/en/blog/ux-design/the-difference-between-ux-and-ui-design-a-laymans-guide/',
            },
            {
                name: 'Information Architecture',
                description: 'Find the needs and wants for my clients, to develop an effective user experience for their business',
                source: 'https://www.usability.gov/what-and-why/information-architecture.html',
            },
            {
                name: 'Site Mapping',
                description: 'Navigational structure is a must, especially for SEO',
                source: 'https://www.techopedia.com/definition/5393/site-map',
            },
            {
                name: 'Prototyping',
                description: 'Developed quick rapid protoypes, allowing early user feedback from clients, with tools such as InvisionApp and Axure',
                source: 'https://www.experienceux.co.uk/faqs/what-is-a-website-prototype/',
            },
            {
                name: 'Wireframing & Mock-ups',
                description: 'Created and designed many wireframes and mockups, from low fidelity to high fidelity',
                source: 'https://www.lucidchart.com/blog/wireframes-vs-mockups',
            },
            {
                name: 'Object Oriented Design',
                description: 'I develop concepts for programming to better plan out the code',
                source: 'http://www.selectbs.com/process-maturity/what-is-object-oriented-design',
            },
            {
                name: 'ADA Compliance',
                description: 'Develop accessible UI and follow ADA compliance for the best user experience.',
                source: 'https://www.w3.org/WAI/fundamentals/accessibility-intro/',
            },
            {
                name: 'Agile Methodology',
                description: 'Worked in an agile environment, collaborating with developers, quality assurance, business analyst, and product owners',
                source: 'https://www.infoworld.com/article/3237508/agile-development/what-is-agile-methodology-modern-software-development-explained.html'
            }
        ],
    },
];

export default STATS;