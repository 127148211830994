import React, { PureComponent } from 'react';
import classNames from 'classnames';
import STATS from '../../constants/stats';
import Tags from '../../components/Tags';

import './About.scss';

const yearRange = () => {
    var oneDay = 360 * 24 * 60 * 60 * 1000; // days*hours*minutes*seconds*milliseconds
    var firstDate = new Date('2011-04-01');
    var secondDate = new Date();

    return Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
}

class About extends PureComponent {
    render() {
        const { active } = this.props;

        return (
            <section className={classNames('section', { active: active === 1 })} id="About">
                <div className="aboutContainer">
                    <div>
                        <h2>{yearRange()}+ Years of Experience</h2>
                        <h3>Genuine passion in developing and refining web apps with my experience skill set.</h3>
                    </div>
                    <div className="aboutTags">
                        {STATS.map((stat, i) => (
                            <div key={`about_tag_${i}`} className="card">
                                <h4>{stat.title}</h4>
                                <Tags tags={stat.items} />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        );
    }
}

export default About;