import React, { Component } from 'react';
import './Home.scss';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Home extends Component {
    render() {
        // const { showLab } = this.props;
        return (
            <section className="section" id="Home">
                <h1><span id="FName"></span>&nbsp;<span id="LName"></span></h1>
                <h2><span id="SubText"></span><span id="SubText2"></span><span id="SubText3"></span></h2>            
                <div id="Source">
                    <OverlayTrigger 
                        placement="top" 
                        overlay={<Tooltip id={`TooltipState_Resume`}>Resume</Tooltip>}>
                        <a 
                            href="https://docs.google.com/document/d/134uWJyM6Kojn8Bu3o2FZbXQQlbLreTjN9FyJAmjX-Jo/edit?usp=sharing" 
                            rel="noopener noreferrer" 
                            target="_blank">
                            <FontAwesomeIcon icon="file-alt" />
                        </a>
                    </OverlayTrigger>
                    <OverlayTrigger 
                        placement="top" 
                        overlay={<Tooltip id={`TooltipState_LinkedIn`}>LinkedIn</Tooltip>}>
                        <a 
                            href="https://www.linkedin.com/in/joshua-vazquez-a3709018" 
                            rel="noopener noreferrer" 
                            target="_blank">
                            <FontAwesomeIcon icon={["fab", "linkedin"]} />
                        </a>
                    </OverlayTrigger>
                    {/* <OverlayTrigger 
                        placement="top" 
                        overlay={<Tooltip id={`TooltipState_Flask`}>Art Lab</Tooltip>}>
                        <a
                            onClick={showLab}
                            // href="https://joshvaz.com/lab" 
                            rel="noopener noreferrer" 
                            target="_blank">
                            <FontAwesomeIcon icon="flask" />
                        </a>
                    </OverlayTrigger> */}
                    {/*<div className="hidden-xs hidden-sm"><br /></div>
                    <a href="https://joshvaz.itch.io/dipmu" rel="noopener noreferrer" target="_blank">DPG (Demo) v0.3.0</a>*/}
                </div>
                <a id="ScrollIndicator" href="#About">
                    <FontAwesomeIcon icon="angle-down" className="angleDown" />
                    <FontAwesomeIcon icon="angle-down" className="angleDown" />
                </a>
            </section>
        )
    }
}

export default Home;