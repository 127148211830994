import React, { PureComponent } from 'react';
import classNames from 'classnames';
import TESTIMONIALS from '../../constants/testimonials';

import './Testimonials.scss';

class Testimonials extends PureComponent {
    render() {
        const { active } = this.props;

        return (
            <section className={classNames('section', { active: active === 3 })} id="Testimonials">
                <div className="testimonialsContainer">
                    <div>
                        <h2>What They Say</h2>
                        <h3>I've had the privilege to work with extremely talented colleagues and clients throughout the course of my career.</h3>
                    </div>
                    <div className="testimonialsQuotes">
                        {TESTIMONIALS.map(test => (
                            <div className="card">
                                <p>"{test.testimonial}"</p>
                                <h4>{test.name}</h4>
                                <h5><span>{test.position}</span> | {test.company}</h5>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        );
    }
}

export default Testimonials;