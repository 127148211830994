import React, { Component } from 'react';
import $ from 'jquery';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight, faAngleLeft, faAngleDown, faFileAlt , faExternalLinkSquareAlt, faFlask, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

import Nav from './components/Navigation';
import Modal from './components/Modal';

import Portfolio from './Portfolio';
// import Lab from './Lab';

// Sections
import Home from './scenes/Home';
import About from './scenes/About';
import Work from './scenes/Work';
import Testimonials from './scenes/Testimonials';
import Contact from './scenes/Contact';

import './assets/scss/style.scss';

library.add(faAngleRight, faAngleDown, faExternalLinkSquareAlt, faAngleLeft, faFileAlt, faLinkedin, faFlask, faTimes);

class App extends Component {

  constructor(props) {
    super(props);

    this.portfolio = new Portfolio();

    this.state = {
      showLab: false,
      active: 0,
      openMenu: false,
      showModal: false,
      modalTite: null,
      modalSubTitle: null,
      modalContent: null,
    }

    this.openMenu = this.openMenu.bind(this);
    this.onResize = this.onResize.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showLab = this.showLab.bind(this);
    this.hideLab = this.hideLab.bind(this);
  }

  componentDidMount() {
    this.portfolio.Intro.start();

    document.getElementById('Page').scrollTop = 0;

    window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  openMenu(open) {
    if (open) {
      $('#nav').addClass('open')
    } else {
      $('#nav').removeClass('open')
    }
    this.setState({
      openMenu: open,
    })
  }

  onResize() {
    var w = window.innerWidth;
    var h = window.innerHeight;

    // Resize Hexes
    var renderer = this.portfolio.Intro.renderer;
   
    // this part resizes the canvas but keeps ratio the same    
    renderer.view.style.width = w + 'px'
    renderer.view.style.height = h + 'px'

    // generate Hex
    var $col = (Math.round(window.innerWidth / this.portfolio.Intro.hexW)) + 2 // plus 1 to fill extra space
    var $row = (Math.round(window.innerHeight / this.portfolio.Intro.hexH)) + 2 // plus 1 to fill extra space
    var startIndex = null;

    if ($row > this.portfolio.Intro.row) {
        startIndex = this.row
        this.portfolio.Intro.row = $row
        this.portfolio.Intro.generateHex(startIndex, null, true)
    }

    if ($col > this.portfolio.Intro.col) {
        startIndex = this.portfolio.Intro.col
        this.portfolio.Intro.col = $col
        this.portfolio.Intro.generateHex(null, startIndex, true)
    }

    // this part adjusts the ratio:
    renderer.resize(w, h)

    if (w > 600) {
      this.setState({
        openMenu: false,
      }) 
    }
  }

  handleScroll(e) {
    if (e.target.classList.contains('page')) {
      //Nav
      let active = 0;
      // console.log(e.target)
      const scrollTop = e.target.scrollTop;
      // console.log(scrollTop)
      const _index = scrollTop/window.innerHeight;
      switch(true) {
        case (_index >= 0) && (_index < 0.5):
          active = 0;
          break;
        case (_index >= 0.5) && (_index < 1.5):
          active = 1;
          break;
        case (_index >= 1.5) && (_index < 2.5):
          active = 2;
          break;
        case (_index >= 2.5) && (_index < 3.5):
          active = 3;
          break;
        case (_index >= 3.5):
          active = 4;
          break;
        default:
          active = 0;
          break;
      }

      if (this.portfolio.Intro.canNavigate) {
        this.setState({
          active: active,
        })

        if (scrollTop > 20) {
          $('#logo').addClass('header').removeClass('home');
          $('#nav').addClass('show');
        } else {
          $('#logo').addClass('home').removeClass('header');
          $('#nav').removeClass('show');
        }

        if (active === 0) {
          $('#Home').addClass('home').removeClass('notActive');
        } else {
          $('#Home').addClass('notActive').removeClass('home');
        }
      }
    }
  }

  showModal(title, subTitle, modalContent) {
    this.setState({
        showModal: true,
        modalTite: title,
        modalSubTitle: subTitle,
        modalContent: modalContent,
    })
  }

  closeModal() {
    this.setState({
      showModal: false,
      modalTite: null,
      modalSubTitle: null,
      modalContent: null,
    })
  }

  showLab() {
    this.setState({
      showLab: true,
    })
  }

  hideLab() {
    this.setState({
      showLab: false,
    })
  }

  render() {
    const { showLab, active, openMenu, showModal, modalTite, modalSubTitle, modalContent } = this.state;

    return (
      <>
        <div id="Page" className={`page container-fluid ${showLab ? 'lab' : ''}`} onScroll={this.handleScroll}>
          <Home showLab={this.showLab} />
          <About active={active} />
          <Work active={active} showModal={this.showModal} />
          <Testimonials active={active} />
          <Contact active={active} />
          {/* <Lab hideLab={this.hideLab} showLab={showLab} /> */}
        </div>
        <Nav active={active} open={openMenu} openMenu={this.openMenu} />
        <Modal
          title={modalTite}
          subTitle={modalSubTitle}
          content={modalContent}
          showModal={showModal}
          closeModal={this.closeModal}
        />
        <div id="bkgFilter"></div>
      </>
    );
  }
}

export default App;
