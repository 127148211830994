import React, { Component } from 'react';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Modal.scss';

class Modal extends Component {
    render() {
        const { title = null, subTitle = null, content = null, showModal = false, closeModal = () => {} } = this.props;

        return (
            <div className={classNames('jv_modal', { show: showModal })}>
                <div className="modalHeader">
                    <h4>{title}</h4>
                    <button className="closeButton" onClick={closeModal}>
                        <FontAwesomeIcon icon="times" />
                    </button>
                </div>
                {subTitle && (
                    <h5>{subTitle}</h5>
                )}
                <div className="modalBody">
                    {content}
                </div>
            </div>
        )
    }
}

export default Modal;