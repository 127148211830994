import React from 'react';
import classNames from 'classnames';

import { ReactComponent as Logo } from '../../assets/media/logo.svg';

import './Navigation.scss';

const NavItems = [
    {
        label: 'About',
        target: '#About',
    },
    {
        label: 'Work',
        target: '#Work',
    },
    {
        label: 'Testimonials',
        target: '#Testimonials',
    },
    {
        label: 'Contact',
        target: '#Contact',
    },
];

const Nav = ({ active, open, openMenu }) => {
    return (
        <>
            <nav id="nav">
                <div id="iconNav" className={classNames({ open: open })} onClick={() => openMenu(!open)}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <ul className={classNames({ mobileOpen: open })}>
                    {NavItems.map((n, i) => (
                        <li key={`NavItem_${n.label}`}>
                            <a
                                href={n.target}
                                rel="noopener noreferrer"
                                className={active === (i + 1) ? 'active' : null}
                                onClick={() => openMenu(false)}
                            >
                                {n.label}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
            <a href="#Home" rel="noopener noreferrer" id="logo" onClick={() => openMenu(false)}>
                <Logo />
            </a>
        </>
    )
}

export default Nav;