import React, { PureComponent } from 'react';
import classNames from 'classnames';
import './Contact.scss';

class Contact extends PureComponent {
    render() {
        const { active } = this.props;
        return (
            <section className={classNames('section', { active: active === 4 })} id="Contact">
                <div className="contactContainer">
                    <h2>Contact Me</h2>
                    <h3 className="contactText1">Feel free to reach me at <a href="mailto:joshvaz89@gmail.com">joshvaz89@gmail.com</a></h3>
                    <h3 className="contactText2">
                        <a 
                            href="https://www.linkedin.com/in/joshua-vazquez-a3709018" 
                            rel="noopener noreferrer" 
                            target="_blank">
                                LinkedIn
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a 
                            href="https://joshvaz.itch.io/" 
                            rel="noopener noreferrer" 
                            target="_blank">
                                Itch.io
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a
                            href="https://codepen.io/joshvaz"
                            rel="noopener noreferrer" 
                            target="_blank">
                                Codepen
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a 
                            href="https://docs.google.com/document/d/134uWJyM6Kojn8Bu3o2FZbXQQlbLreTjN9FyJAmjX-Jo/edit?usp=sharing" 
                            rel="noopener noreferrer" 
                            target="_blank">
                                My Resume
                        </a>
                    </h3>
                </div>
            </section>
        );
    }
}

export default Contact;