const yearRange = () => {
    var oneDay = 360 * 24 * 60 * 60 * 1000; // days*hours*minutes*seconds*milliseconds
    var firstDate = new Date('2011-04-01');
    var secondDate = new Date();

    return Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
}

const INTRO = {
    fname: 'Joshua',
    lname: 'Vazquez',
    subheading: 'Your',
    subheading2: ['Front-End', 'UI/UX', 'Full-Stack', 'Web'],
    subheading3: 'Developer',
    message: `Welcome, I am your Digital Front-End and UI/UX Ninja with ${yearRange()} years of experience and specialization in developing web applications.`,
    message2: 'See some of my work below...',
}

export default INTRO;