import React, { Component } from 'react';
import classNames from 'classnames';

import './Tags.scss';

const TagItem = (item, index) => {

    const DisplayName = () => (
        <span>
            {item.source &&
                <React.Fragment>
                    <a href={item.source} target="_blank" rel="noopener noreferrer">{item.name}</a>
                </React.Fragment>
            }
            {item.source === null &&
                <React.Fragment>{item.name}</React.Fragment>
            }
        </span>
    );

    return (
        <div className={classNames('tag', { link: !!item.source })} key={`StatItem_${index}`}>
            {typeof(item) === 'string' && 
                <React.Fragment>{item}</React.Fragment>
            }
            {typeof(item) === 'object' && 
                <React.Fragment>
                    {DisplayName()}
                </React.Fragment>
            }
        </div>
    )
};

class Tags extends Component {
    render() {
        const { tags } = this.props;

        return (
            <div className="tags">
                {tags.map((tag, index) => {
                    return TagItem(tag, index)
                })}
            </div>
        )
    }
}

export default Tags;