import React, { Component } from 'react';
import classNames from 'classnames';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import WORK from '../../constants/work';
import Tags from '../../components/Tags';
import WorkLightbox from '../../components/Lightbox';

import './Work.scss';

class Work extends Component {
    constructor(props) {
        super(props)

        this.state = {
            images: [],
            photoIndex: 0,
            isOpen: false,
        };

        this.onOpenRequest = this.onOpenRequest.bind(this);
        this.onCloseRequest = this.onCloseRequest.bind(this);
        this.onMovePrevRequest = this.onMovePrevRequest.bind(this);
        this.onMoveNextRequest = this.onMoveNextRequest.bind(this);
        this.modalContent = this.modalContent.bind(this);
    }

    onOpenRequest(images, index) {
        this.setState({
            images: images,
            photoIndex: index,
            isOpen: true
        })
    }

    onCloseRequest() {
        this.setState({
            images: [],
            photoIndex: 0,
            isOpen: false
        })
    }

    onMovePrevRequest() {

        const { images, photoIndex } = this.state;

        this.setState({
            photoIndex: (photoIndex + images.length - 1) % images.length,
          })
    }

    onMoveNextRequest() {

        const { images, photoIndex } = this.state;

        this.setState({
          photoIndex: (photoIndex + 1) % images.length,
        })
    }

    modalContent(work) {
        return (
            <div className="workModalContent">
                <div className="workDescription">
                    {work.link && (
                        <a href={work.link} target="_blank" rel="noopener noreferrer">{work.link}</a>
                    )}
                    {work.description}
                    <Tags tags={work.tags} />
                </div>
                <div className="workScreenshot">
                    {work.screenshots !== null &&
                        <Carousel 
                            showStatus={false}
                            showThumbs={false} 
                            infiniteLoop={true}
                            onClickItem={(index) => { this.onOpenRequest(work.screenshots, index) }}>
                            {work.screenshots.map((screenshot, index) => (
                                <div key={`WorkScreenshot_${index}`}>
                                    <img src={screenshot} alt={`${work.title} Screenshot ${index + 1}`} />
                                </div>
                            ))}
                        </Carousel>
                    }
                    {work.screenshots === null && (
                        <img className="cover" src={work.cover} alt={work.description} />
                    )}
                </div>
            </div>
        )
    }

    render() {
        const { active, showModal } = this.props;
        const { images, photoIndex, isOpen } = this.state;
        
        return (
            <section className={classNames('section', { active: active === 2 })} id="Work">
                <div className="workContainer">
                    <div>
                        <h2>My Work</h2>
                        <h3>View some of the projects I have worked on for my clients.</h3>
                    </div>
                    <div className="workItems">
                        {WORK.map((work, i) => (
                            <div key={`work_card_${i}`} className="card" onClick={() => showModal(work.title, work.subTitle, this.modalContent(work))}>
                                <img className="cover" src={work.cover} alt={work.title} />
                                <h4>{work.title}</h4>
                                <h5>{work.subTitle}</h5>
                            </div>
                        ))}
                        <WorkLightbox 
                            images={images} 
                            photoIndex={photoIndex}
                            isOpen={isOpen}
                            onCloseRequest={this.onCloseRequest}
                            onMovePrevRequest={this.onMovePrevRequest}
                            onMoveNextRequest={this.onMoveNextRequest}
                        />
                    </div>
                </div>
            </section>
        );
    }
}

export default Work;