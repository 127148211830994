import React from 'react';

import COLBCover from '../assets/media/work/colb/COLBCover.png';
import COLBScreenshot1 from '../assets/media/work/colb/COLB-Screenshot-1.png';
import COLBScreenshot2 from '../assets/media/work/colb/COLB-Screenshot-2.png';
import COLBScreenshot3 from '../assets/media/work/colb/COLB-Screenshot-3.png';

import CBDSCover from '../assets/media/work/cbds/CBDSCover.png';
import CBDSScreenshot1 from '../assets/media/work/cbds/CBDS-Screenshot-1.png';
import CBDSScreenshot2 from '../assets/media/work/cbds/CBDS-Screenshot-2.png';

import ZincCover from '../assets/media/work/zinc/ZincCover.png';
import ZincScreenshot1 from '../assets/media/work/zinc/Zinc-Screenshot-1.png';
import ZincScreenshot2 from '../assets/media/work/zinc/Zinc-Screenshot-2.png';
import ZincScreenshot3 from '../assets/media/work/zinc/Zinc-Screenshot-3.png';
import ZincScreenshot4 from '../assets/media/work/zinc/Zinc-Screenshot-4.png';

import BanditCover from '../assets/media/work/bandit/BanditCover.png';
import BanditScreenshot1 from '../assets/media/work/bandit/Bandit-Screenshot-1.png';
import BanditScreenshot2 from '../assets/media/work/bandit/Bandit-Screenshot-2.png';
import BanditScreenshot3 from '../assets/media/work/bandit/Bandit-Screenshot-3.png';
import BanditScreenshot4 from '../assets/media/work/bandit/Bandit-Screenshot-4.png';

import SiteBuilderCover from '../assets/media/work/sitebuilder/SiteBuilderCover.png';
import SiteBuilderScreenshot1 from '../assets/media/work/sitebuilder/SiteBuilder-Screenshot-1.png';
import SiteBuilderScreenshot2 from '../assets/media/work/sitebuilder/SiteBuilder-Screenshot-2.png';
import SiteBuilderScreenshot3 from '../assets/media/work/sitebuilder/SiteBuilder-Screenshot-3.png';
import SiteBuilderScreenshot4 from '../assets/media/work/sitebuilder/SiteBuilder-Screenshot-4.png';
import SiteBuilderScreenshot5 from '../assets/media/work/sitebuilder/SiteBuilder-Screenshot-5.png';

import Big4ConsultCover from '../assets/media/work/big4consult/Big4Consult.png';
import For500Cover from '../assets/media/work/for500/For500.png';

const WORK = [
    {
        title: 'Citizens Online Banking',
        subTitle: 'Citizens Bank',
        description: (
            <>
                <p>Online Banking is the online banking web application for Citizens Bank' customers.</p>
                <h6>GOALS</h6>
                <ul>
                    <li>New and improved online banking experience for Citizens' customers, resulting in positive feedback and satisfaction.</li>
                </ul>
                <h6>WORK</h6>
                <ul>
                    <li>Developed and managed UI components in Angular.</li>
                    <li>Consulted and guided developers of the project on how to implement the Design System and the UI components.</li>
                </ul>
            </>
        ),
        cover: COLBCover,
        screenshots: [COLBScreenshot1, COLBScreenshot2, COLBScreenshot3],
        process: [],
        link: 'https://www.citizensbankonline.com',
        tags: ['Node.js', 'Angular', 'Sass', 'MFE']
    },
    {
        title: 'Citizens Design System',
        subTitle: 'Citizens Bank',
        description: (
            <>
                <p>Citizens Design System (CBDS) helps teams build engaging, consistent, accessible, and cohesive Citizens digital experiences. CBDS provides branding, visual style, accessible UI components, UX patterns, content guidelines, tool-kits, and resources backed by user research and current best practices.</p>
                <h6>GOALS</h6>
                <ul>
                    <li>Improved the collaboration between POs, Designers and Developers.</li>
                    <li>Save time and budget for designers and developers with references and resources.</li>
                </ul>
                <h6>WORK</h6>
                <ul>
                    <li>Developed and managed web components for multiple platforms. (HTML/CSS, Angular, and React)</li>
                    <li>Tested and provided documentation of guidelines, API, CSS Architecture and Accessibility of web components.</li>
                    <li>Consultant for multiple projects using the design system.</li>
                </ul>
            </>
        ),
        cover: CBDSCover,
        screenshots: [CBDSScreenshot1, CBDSScreenshot2],
        process: [],
        link: 'https://designsystem.citizensbank.com',
        tags: ['Node.js', 'Angular', 'React', 'Sass', 'StorybookJS']
    },
    {
        title: 'Zinc',
        subTitle: 'Chasm Communications, Inc.',
        description: (
            <>
                <p>A web application for project managers and business owners in managing their SOWs in sync with their Zoho Projects portal.</p>
                <h6>GOALS</h6>
                <ul>
                    <li>Project managers can easily compare their SOWs to their project plans and tasks in Zoho.</li>
                    <li>Easily have an overview of project progress with the project tacker.</li>
                    <li>Saved time on tracking and managing task hours of big projects.</li>
                </ul>
                <h6>WORK</h6>
                <ul>
                    <li>Developed and managed a full stack environment, from Front-End (React) to Back-End (Node.js, Express), and the hosting on a cloud service (DigitalOcean).</li>
                </ul>
            </>
        ),
        cover: ZincCover,
        screenshots: [ZincScreenshot1, ZincScreenshot2, ZincScreenshot3, ZincScreenshot4],
        process: [],
        link: 'https://zinc.chasmux.com',
        tags: ['Node.js', 'React', 'Sass', 'Material UI', 'Express', 'MySQL', 'Zoho Projects API']
    },
    {
        title: 'Online Design System',
        subTitle: 'Big 4 Consulting Firms',
        description: (
            <>
                <p>The online design system served as a hub for learning style patterns and providing downloadable resources for our product owners and developers.</p>
                <h6>GOALS</h6>
                <ul>
                    <li>UI Designers have better collaboration with the developers and Product Owners.</li>
                    <li>Product Owners of multiple teams now come to better agreements, providing clarity on the Acceptance Criteria.</li>
                    <li>UI designers can easily regulate all of the UI patterns and provide resources for the developers.</li>
                </ul>
                <h6>WORK</h6>
                <ul>
                    <li>Developed a digital style guide and design system with React framework.</li>
                </ul>
            </>
        ),
        cover: Big4ConsultCover,
        screenshots: null,
        process: [],
        link: null,
        tags: ['HTML5', 'CSS3', 'Sass', 'Node.js', 'React', 'Client API Services', 'VSTS'],
    },
    {
        title: 'Bandit',
        subTitle: 'Chasm Communications, Inc.',
        description: (
            <>
                <p>A web application for UI Designers to curate their UX findings to solve UI patterns, with a powerful tagging system.</p>
                <h6>GOALS</h6>
                <ul>
                    <li>UI/UX Designers rapidly prototype their mock ups with their finding notes to present to clients.</li>
                    <li>Coherence and better collaboration between UI designers and clients.</li>
                </ul>
                <h6>WORK</h6>
                <ul>
                    <li>Developed and managed a full stack environment, from Front-End (React) to Back-End (Node.js, Express), and the hosting on a cloud service (Google Cloud &amp; DigitalOcean).</li>
                </ul>
            </>
        ),
        cover: BanditCover,
        screenshots: [BanditScreenshot1, BanditScreenshot2, BanditScreenshot3, BanditScreenshot4],
        process: [],
        link: 'https://uxbandit.com',
        tags: ['Node.js', 'React', 'Sass', 'Material UI', 'Express', 'MySQL', 'Google Cloud Services & APIs'],
    },
    {
        title: 'Auditing Web Application',
        subTitle: 'Big 4 Consulting Firms',
        description: (
            <>
                <p>A web application for one of the Big 4 Consulting Firms to audit their clients. Using superior advanced analytics, big data manipulation, and AI to facilitate efficient and painless data access and analysis of huge data sets. Allow audit teams to pinpoint the key audit risk areas and target their testing accordingly.</p>
                <h6>GOALS</h6>
                <ul>
                    <li>Improve the work performance of the auditors.</li>
                    <li>Provide insights on business trends and relationships that otherwise would not have surfaced.</li>
                    <li>Easily mitigate, aggregate, and facilitate the auditor's workload.</li>
                    <li>Improve the management and usability of auditing tools and services.</li>
                </ul>
                <h6>WORK</h6>
                <ul>
                    <li>Worked as a UI developer across multiple teams in an agile environment with FE/BE developers, QA, and POs.</li>
                    <li>Collaborated with UI designers to understand and support their UI patterns.</li>
                    <li>Developed and managed UI components in React framework.</li>
                </ul>
            </>
        ),
        cover: Big4ConsultCover,
        screenshots: null,
        process: [],
        link: null,
        tags: ['HTML5', 'CSS3', 'Sass', 'Node.js', 'React', 'Agile Environment'],
    },
    {
        title: 'Financial Account Management System',
        subTitle: 'Fortune 500 Company',
        description: (
            <>
                <p>Web applications for one of the Fortune 500 companies to manage financial accounts.</p>
                <h6>GOALS</h6>
                <ul>
                    <li>Improve the work performance and usability of managing financial accounts.</li>
                </ul>
                <h6>WORK</h6>
                <ul>
                    <li>Styled existing Angular 2 components and developed CSS/Sass patterns.</li>
                    <li>Required to facilitate the UI components and match all mockups.</li>
                    <li>Collaborated with UI Designer to provide feedback and UI patterns and designs.</li>
                </ul>
            </>
        ),
        cover: For500Cover,
        screenshots: null,
        process: [],
        link: null,
        tags: ['HTML5', 'CSS3', 'Sass', 'Node.js', 'Angular 2'],
    },    
    {
        title: 'Online Institutional Portfolio Management System',
        subTitle: 'Fortune 500 Company',
        description: (
            <>
                <p>Web application for one of the Fortune 500 companies. An interactive proprietary online portfolio management system for institutions, giving the users constant access to their portfolio and the Fortune 500 Company’s inventory and research.</p>
                <h6>GOALS</h6>
                <ul>
                    <li>“Virtual” trades and strategies for pre-purchase and decision-making analysis.</li>
                    <li>Online interaction and immediate results means more time building strategy with your financial advisor and less time gathering analytics.</li>
                    <li>Everything in one place: analytics, accounting and reporting, research and links to relevant information.</li>
                </ul>
                <h6>WORK</h6>
                <ul>
                    <li>Styled existing Angular 1 components and developed CSS/Less patterns.</li>
                    <li>Required to facilitate the UI components and match all mockups.</li>
                    <li>Collaborated with UI Designer to provide feedback and UI patterns and designs.</li>
                    <li>Customize the UI grid framework for Angular 1 to match UX patterns from mockups.</li>
                </ul>
            </>
        ),
        cover: For500Cover,
        screenshots: null,
        process: [],
        link: null,
        tags: ['HTML5', 'CSS3', 'Less', 'Node.js', 'Angular 1', 'Angular UI Grid'],
    },
    {
        title: 'Auditing & Advisory Service Web Applications',
        subTitle: 'Big 4 Consulting Firms',
        description: (
            <>
                <p>Several web applications for one of the Big 4 consulting firms to manage their services.</p>
                <h6>GOALS</h6>
                <ul>
                    <li>Improved the UI/UX which resulted to better performances on the apps and work environments.</li>
                </ul>
                <h6>WORK</h6>
                <ul>
                    <li>Developed front-end UI web applications in HTML, CSS and JavaScript to match approved UI wifreframes and high-fidelity mockups. </li>
                    <li>Styled the existing code to create responsive designs and page layouts based upon dynamic data being injected into the page. </li>
                    <li>Worked with a team of Usability Specialists, UI/UX Designers and Back-end Developers to identify the most appropriate jQuery Mobile patterns to support the user stories. </li>
                    <li>Developed a fully functional HTML prototype with on-screen guidance for user testing and acceptance.</li>
                    <li>Styled the existing code and Kendo UI components to match the approved UI mockups using HTML, CSS and JavaScript.</li>
                    <li>Please see my resume for more information on these projects.</li>
                </ul>
            </>
        ),
        cover: Big4ConsultCover,
        screenshots: null,
        process: [],
        link: null,
        tags: ['HTML5', 'CSS3', 'JavaScript', 'jQuery', 'jQuery UI', 'Kendo UI', 'Bootstrap'],
    },
    {
        title: 'Chasm SiteBuilder',
        subTitle: 'Chasm Communications, Inc.',
        description: (
            <>
                <p>Chasm SiteBuilder is a powerful CMS tailored to users with no experience in writing code. SiteBuilder provides tools for easy web building support, such as drag-n-drop pre-built components and a custom contact form editor. Other features included: Google Analytics, User Management, and a Media Manager.</p>
                <h6>GOALS</h6>
                <ul>
                    <li>Chasm team and clients can easily and quickly manage the content and site configurations.</li>
                    <li>Clients have easy access to view their google analytics, manage users and media files within SiteBuilder.</li>
                </ul>
                <h6>WORK</h6>
                <ul>
                    <li>Developed a custom CMS 3rd party tool for ModX using PHP, jQuery, and KnockoutJS.</li>
                    <li>Setup Google Analytics API to render analytics within the CMS system.</li>
                </ul>
            </>
        ),
        cover: SiteBuilderCover,
        screenshots: [SiteBuilderScreenshot1, SiteBuilderScreenshot2, SiteBuilderScreenshot3, SiteBuilderScreenshot4, SiteBuilderScreenshot5],
        process: [],
        link: null,
        tags: ['ModX CMS & Cloud', 'PHP', 'MySQL'],
    },
]

/* 
• Participate in discussions with clients and team members about technical best
practices and emerging standards to shape the user experience and identify
optimal technical solutions.
• Collaborate with the Usability Specialists & UI/UX designers to help define designs
and create engaging user interfaces utilizing the latest web technologies.
• Develop and test the front-end UI for web and mobile sites across multiple
browsers, platforms and devices.
• Integrate the HTML/CSS/JavaScript into the back-end environment to match the
client approved UI designs.
*/

export default WORK;