const TESTIMONIALS = [
    {
        name: 'Jason Rewald',
        position: 'Associate Director, UX',
        company: 'Chasm Communications, Inc.',
        testimonial: 'I have worked with Josh going on 5 years. I find Josh to be intelligent, professional, and possess a talent to solve the unsolvable through creative means. Having Josh on my team proved to be immensely valuable on countless occasions.',
    },
    {
        name: 'Aaron Murphy',
        position: 'Sr. Software Developer',
        company: 'AgileThought',
        testimonial: 'It was a pleasure collaborating with Joshua over a long term project. His dedication to quality and his wealth of knowledge made him an invaluable asset to our team.',
    },
    {
        name: 'Carolos Constantakatos',
        position: 'Senior Developer',
        company: 'AgileThought',
        testimonial: 'Joshua is a highly talented and hardworking programmer. What really makes him standout is his passion and excitement for coding and learning new things. He is always tinkering with different project ideas and technologies. To top it off he is an amazing person that is a pleasure to work with.',
    },
    {
        name: 'Vivek Khera',
        position: 'Manager & Agile Product Owner',
        company: 'One of the Big 4 Consulting Firms',
        testimonial: 'I\'ve really enjoyed working with Josh over the past 2 years, he would provide insight and knowledge on design and improving the UI. Also, answering all of my questions and was willing to help in everything with a positive attitude.',
    },  
    // {
    //     name: 'Kevin Razmus',
    //     position: 'Founder & CEO',
    //     company: 'Razmus Media, LLC.',
    //     testimonial: 'He\'s awesome!',
    // },
    // {
    //     name: 'Nestor Padilla',
    //     position: 'Senior Developer',
    //     company: 'Pegasus TransTech',
    //     testimonial: 'He\'s awesome!',
    // },
    // {
    //     name: 'Jordan Carney',
    //     position: 'Lead Application Developer',
    //     company: 'Deloitte',
    //     testimonial: 'Hello...',
    // },
    // {
    //     name: 'Jennifer Williams',
    //     position: 'President & Owner',
    //     company: 'Chasm Communications, Inc.',
    //     testimonial: 'He\'s awesome!',
    // },
    // {
    //     name: 'BJ Fink',
    //     position: 'Manager, Application Architecture',
    //     company: 'Deloitte',
    //     testimonial: 'He\'s awesome!',
    // },
    // {
    //     name: 'Daniel Ordóñez',
    //     position: 'Lead Sr. UI/UX Designer',
    //     company: 'Chasm Communications, Inc.',
    //     testimonial: 'He\'s awesome!',
    // },
    // {
    //     name: 'Mitch Obert',
    //     position: 'UI Developer',
    //     company: 'Chasm Communications, Inc.',
    //     testimonial: 'He\'s awesome!',
    // },
    // {
    //     name: 'David Almodovar',
    //     position: 'Managing Partner',
    //     company: 'Almodovar Group, LLC.',
    //     testimonial: 'He\'s awesome!',
    // },
];

export default TESTIMONIALS;