import $ from 'jquery';
import * as PIXI from 'pixi.js'

import HexImg from './assets/media/NewHex.svg';
import PlaceholderImg from './assets/media/placeholder.png';

import INTRO from './constants/intro';

const TWEEN = require('@tweenjs/tween.js');

/*var cancelRequestAnimFrame = (function () {
  return window.cancelAnimationFrame ||
    window.webkitCancelRequestAnimationFrame ||
    window.mozCancelRequestAnimationFrame ||
    window.oCancelRequestAnimationFrame ||
    window.msCancelRequestAnimationFrame ||
    clearTimeout
})()*/

var requestAnimFrame = (function () {
  return window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function ( /* function */ callback, /* DOMElement */ element) {
      return window.setTimeout(callback, 1000 / 60)
    }
})()

class Intro {
  constructor(portfolio) {
    this.portfolio = portfolio;
    this.pixiInited = false;
    this.isTyping = false;
    this.canNavigate = false;

    // You need to create a root container that will hold the scene you want to draw.
    this.stage = new PIXI.Container();

    // You can use either `new PIXI.WebGLRenderer`, `new PIXI.CanvasRenderer`, or `PIXI.autoDetectRenderer`
    // which will try to choose the best renderer for the environment you are in.
    this.renderer = new PIXI.autoDetectRenderer(800, 600)
    this.renderer.view.style.position = 'absolute'
    this.renderer.view.style.display = 'block'
    this.renderer.autoResize = true
    this.renderer.resize(window.innerWidth, window.innerHeight)

    // The renderer will create a canvas element for you that you can then insert into the DOM.
    document.body.appendChild(this.renderer.view)

    //Other Props
    this.shiftAnimLogo = null;
    this.animCreateLogo = null;
    this.flickerCaret = null;
    this.typer = null;
    this.animText = null;
    this.hexTween = [];
    this.hexW = 239; 
    this.hexH = 239;
    this.col = (Math.round(window.innerWidth / this.hexW)) + 1; // plus 1 to fill whitespace
    this.row = (Math.round(window.innerHeight / this.hexH)) + 1; // plus 1 to fill whitespace
  }

  start() {
    var $this = this;
    $this.animCreateLogo = setInterval(function () {
      $this.createLogo()
    }, 100)
  }

  typeLetters(str, id, charBreak, onComplete, clear) {
    var $this = this;
    var target = document.getElementById(id);
    var Letters = str.split('')
    var typeSpeed = 20;
    var i = 0
    var caret = document.getElementById('caret');

    charBreak = charBreak || null;
    onComplete = onComplete || null;
    clear = clear || false;

    if (caret) {
      caret.remove();
      clearInterval(this.flickerCaret);
    }

    if (this.typer) {
      clearTimeout(this.typer);
    } 

    // Create caret, if clear then empty inner html of target
    if (clear) {
      target.innerHTML = '<span id="caret">&nbsp;&nbsp;</span>';
    } else {
      target.innerHTML += '<span id="caret">&nbsp;&nbsp;</span>';
    }

    // Grab new caret
    caret = document.getElementById('caret');

    // Create Caret flicker
    this.flickerCaret = setInterval(function () {
      var _caret = document.getElementById('caret');
      if (_caret) {
        if (_caret.style.visibility === 'hidden') {
          _caret.style.visibility = 'visible'
        } else {
          _caret.style.visibility = 'hidden'
        }
      }
    }, 600);

    var arr = target.className.split(" ");
    if (arr.indexOf("typing") === -1) {
      target.className += " typing";
    }

    while (i < Letters.length) {
      $this.typer = setTimeout(function (i, charBreak, Letters, onComplete) {
        var caret = document.getElementById('caret')
        var arr = target.className.split(" ");
        var letter = (i === charBreak) ? '<br />' + Letters[i] : Letters[i];
        if (caret) {
          caret.parentNode.removeChild(caret)
          letter = letter + caret.outerHTML
          target.innerHTML += letter
          if (i === (Letters.length - 1)) {
            if (arr.indexOf("typing") !== -1) {
              target.className = target.className.replace(/typing/g, '');
            }
            if (onComplete) {
              onComplete()
            }
          }
        }
      }, typeSpeed * i, i, charBreak, Letters, onComplete)
      i++
    }
  }

  fadeIn(el, duration, display) {
    var s = el.style,
      step = 25 / (duration || 500)
    s.opacity = s.opacity || 0
    s.display = display || 'block';
    (function fade() {
        (s.opacity = parseFloat(s.opacity) + step) > 1 ? s.opacity = 1 : setTimeout(fade, 25);
    })()
  }

  createLogo() {
    var logo = $('#logo .path')
    if (logo.css('stroke-dashoffset') === '0px' && logo.css('fill-opacity') === '0') {
      if (!logo.hasClass('fill')) {
        logo.addClass('fill')
      }
    }

    if (logo.css('fill-opacity') === '1') {
      clearInterval(this.animCreateLogo)
      this.initPixi()
    }
  }

  initPixi() {
    var $this = this;
    if (!this.pixiInited) {
      this.pixiInited = true;
      // Use Pixi's built-in `loader` object to load an image
      PIXI.loader
        .add([HexImg, PlaceholderImg])
        .load(function () {
          $this.setup();
        })
    }
  }

  // This `setup` function will run when the image has loaded
  setup() {
    var $this = this;
    var stage = this.stage;
    var update = function () {
      requestAnimFrame(update);

      TWEEN.update()

      $this.renderer.render($this.stage)

      $this.renderer.gl.flush()
    }
    // Generates Hex's
    this.generateHex(null, null, false)

    //Fade In hex's
    for (var i = 0, len = $this.hexTween.length; i < len; i++) {
      $this.hexTween[i].start()
    }

    // Render the stage   
    this.renderer.render(stage)
    update()
  }

  generateHex($row, $col, start) {
    var $this = this;
    var hexes = []
    var alphas = [0.75, 0.25, 0.5]
    var delayArr = [1, 2, 3, 4, 5]
    var alphaIndex = 0;
    var hexIndex = 0
    var stage = this.stage;
    $row = ($row !== null) ? $row : 0
    $col = ($col !== null) ? $col : 0

    for (var i = $row; i < this.row; i++) {
      for (var j = $col; j < this.col; j++) {
        hexes[hexIndex] = new PIXI.Sprite(PIXI.loader.resources[HexImg].texture)
        hexes[hexIndex].hexIndex = hexIndex
        hexes[hexIndex].position.x = (j * (this.hexW * 0.9))
        hexes[hexIndex].position.y = (i * (this.hexH * 1.56))
        hexes[hexIndex].alpha = 0
        hexes[hexIndex].anchor.set(0.5, 0.5)

        this.hexTween[hexIndex] = new TWEEN.Tween(hexes[hexIndex])
          .to({
            alpha: alphas[alphaIndex],
          }, 800)
          .easing(TWEEN.Easing.Sinusoidal.Out)
          .delay(50 * (i + j))
          .onComplete(function (sprite) {
            new TWEEN.Tween(sprite)
            .to({ alpha: alphas }, 5000)
            .repeat(Infinity)
            .delay(100 * (delayArr[Math.floor(Math.random() * delayArr.length)]))
            .start()
        })

        if (start) {
          this.hexTween[hexIndex].start()
        }

        stage.addChild(hexes[hexIndex])
        alphaIndex = alphaIndex === 3 ? 0 : alphaIndex + 1;
        hexIndex++

        // Shifted hex
        hexes[hexIndex] = new PIXI.Sprite(PIXI.loader.resources[HexImg].texture)
        hexes[hexIndex].hexIndex = hexIndex
        hexes[hexIndex].position.x = (j * (this.hexW * 0.9)) + (this.hexW * 0.45)
        hexes[hexIndex].position.y = (i * (this.hexH * 1.56)) + (this.hexH * 0.78)
        hexes[hexIndex].alpha = 0
        hexes[hexIndex].anchor.set(0.5, 0.5)
        this.hexTween[hexIndex] = new TWEEN.Tween(hexes[hexIndex])
          .to({
            alpha: alphas[alphaIndex]
          }, 800)
          .easing(TWEEN.Easing.Sinusoidal.Out)
          .delay(50 * (i + j))
          .onComplete(function (sprite) {
              new TWEEN.Tween(sprite)
              .to({ alpha: alphas }, 5000)
              .repeat(Infinity)
              .delay(100 * (delayArr[Math.floor(Math.random() * delayArr.length)]))
              .start()
          })

        if (start) {
          this.hexTween[hexIndex].start()
        }

        stage.addChild(hexes[hexIndex])

        if (i === this.row - 1 && j === this.col - 1 && !start) {
          this.hexTween[hexIndex].onComplete(function () {
            $('#bkgFilter').addClass('blur');
            $this.shiftAnimLogo = setTimeout(function () {
              $this.shiftLogo()
            }, 50)
          })
        } else {
          alphaIndex = alphaIndex === 3 ? 0 : alphaIndex + 1;
          hexIndex++;
        }
      }
    }
  }

  shiftLogo() {
    var $this = this;
    if (!$('#logo').hasClass('home')) {
      $('#logo').addClass('home');
      setTimeout(() => {
        $this.typeLetters(INTRO.fname, 'FName', null, function () {
          $this.typeLetters(INTRO.lname, 'LName', null, function () {
            $this.typeLetters(`${INTRO.subheading} `, 'SubText', null, function () {
              $this.typeLetters(INTRO.subheading3, 'SubText3', null, function () {
                $this.typeLetters(INTRO.subheading2[0], 'SubText2', null, function () {
                  var textIndex = 1;
                  $('#ScrollIndicator').addClass('show');
                  $this.fadeIn(document.getElementById('Source'));
                  $('.page').addClass('scroll');
                  $this.canNavigate = true;
                  $this.animText = setInterval(() => {
                    $this.typeLetters(INTRO.subheading2[textIndex], 'SubText2', null, null, true);
                    textIndex = textIndex === 3 ? 0 : textIndex + 1;
                  }, 1000);
                  clearInterval($this.shiftAnimLogo)
                });
              })
            })
          })
        })
      }, 500);
    }
  }
}

export default Intro;